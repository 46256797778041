<template>
  <b-nav-item-dropdown
    ref="navdropdown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="$store.getters.suma"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>
    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('DatumiKojiSuIstekli') }}
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ $store.getters.sumaIstekli }}
        </b-badge>
      </div>
    </li>
    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        @click="zatvoriOtvoriMeni()"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              variant="light-danger"
            >
              <feather-icon icon="UsersIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <b-row>
              <b-col
                md="10"
                xl="10"
              >
                <span class="font-weight-bolder">
                  {{ $t('Kandidati') }}
                </span>
              </b-col>
              <b-col
                md="2"
                xl="2"
              >
                <b-badge
                  pill
                  variant="light-danger"
                >
                  {{ datumiKojiSuIstekliKandidati }}
                </b-badge>
              </b-col>
            </b-row>
          </p>
          <small class="notification-text">{{ $t('DatumiZaKanidateKojiSuIstekli') }}</small>
        </b-media>
      </b-link>
      <b-link
        @click="zatvoriOtvoriMeni()"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              variant="light-danger"
            >
              <feather-icon icon="TruckIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <b-row>
              <b-col
                md="10"
                xl="10"
              >
                <span class="font-weight-bolder">
                  {{ $t('Zaposleni') }}
                </span>
              </b-col>
              <b-col
                md="2"
                xl="2"
              >
                <b-badge
                  pill
                  variant="light-danger"
                >
                  {{ datumiKojiSuIstekliZaposleni }}
                </b-badge>
              </b-col>
            </b-row>
          </p>
          <small class="notification-text">{{ $t('DatumiZaZaposleneKojiSuIstekli') }}</small>
        </b-media>
      </b-link>
      <b-link
        @click="zatvoriOtvoriMeni()"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              variant="light-danger"
            >
              <feather-icon icon="TruckIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <b-row>
              <b-col
                md="10"
                xl="10"
              >
                <span class="font-weight-bolder">
                  {{ $t('Vozila') }}
                </span>
              </b-col>
              <b-col
                md="2"
                xl="2"
              >
                <b-badge
                  pill
                  variant="light-danger"
                >
                  {{ datumiKojiSuIstekliVozila }}
                </b-badge>
              </b-col>
            </b-row>
          </p>
          <small class="notification-text">{{ $t('DatumiZaVozilaKojiSuIstekli') }}</small>
        </b-media>
      </b-link>

    </vue-perfect-scrollbar>
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('DatumiKojiPristizu') }}
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ $store.getters.sumaIsticu }}
        </b-badge>
      </div>
    </li>
    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        @click="zatvoriOtvoriMeni()"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              variant="light-danger"
            >
              <feather-icon icon="UsersIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <b-row>
              <b-col
                md="10"
                xl="10"
              >
                <span class="font-weight-bolder">
                  {{ $t('Kandidati') }}
                </span>
              </b-col>
              <b-col
                md="2"
                xl="2"
              >
                <b-badge
                  pill
                  variant="light-danger"
                >
                  {{ datumiKojiPristizuKandidati }}
                </b-badge>
              </b-col>
            </b-row>
          </p>
          <small class="notification-text">{{ $t('DatumiZaKanidateKojiSeBlizeIsteku') }}</small>
        </b-media>
      </b-link>
      <b-link
        @click="zatvoriOtvoriMeni()"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              variant="light-danger"
            >
              <feather-icon icon="TruckIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <b-row>
              <b-col
                md="10"
                xl="10"
              >
                <span class="font-weight-bolder">
                  {{ $t('Zaposleni') }}
                </span>
              </b-col>
              <b-col
                md="2"
                xl="2"
              >
                <b-badge
                  pill
                  variant="light-danger"
                >
                  {{ datumiKojiPristizuZaposleni }}
                </b-badge>
              </b-col>
            </b-row>
          </p>
          <small class="notification-text">{{ $t('DatumiZaZaposleneKojiSeBlizeIsteku') }}</small>
        </b-media>
      </b-link>
      <b-link
        @click="zatvoriOtvoriMeni()"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              variant="light-danger"
            >
              <feather-icon icon="TruckIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <b-row>
              <b-col
                md="10"
                xl="10"
              >
                <span class="font-weight-bolder">
                  {{ $t('Vozila') }}
                </span>
              </b-col>
              <b-col
                md="2"
                xl="2"
              >
                <b-badge
                  pill
                  variant="light-danger"
                >
                  {{ datumiKojiPristizuVozila }}
                </b-badge>
              </b-col>
            </b-row>
          </p>
          <small class="notification-text">{{ $t('DatumiZaVozilaKojiSeBlizeIsteku') }}</small>
        </b-media>
      </b-link>

    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="zatvoriOtvoriMeni()"
      >
        {{ $t('SvaObaveštenja') }}
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BRow, BCol,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    datumiKojiPristizuKandidati() {
      return this.$store.getters.kandidatiKojimaIsticuDatumi
    },
    datumiKojiPristizuZaposleni() {
      return this.$store.getters.zaposleniKojimaIsticuDatumi
    },
    datumiKojiPristizuVozila() {
      return this.$store.getters.vozilaKojimaIsticuDatumi
    },
    datumiKojiPristizuSuma() {
      return this.$store.getters.sumaIsticu
    },
    datumiKojiSuIstekliKandidati() {
      return this.$store.getters.kandidatiKojimaSuIstekliDatumi
    },
    datumiKojiSuIstekliZaposleni() {
      return this.$store.getters.zaposleniKojimaSuIstekliDatumi
    },
    datumiKojiSuIstekliVozila() {
      return this.$store.getters.vozilaKojimaSuIstekliDatumi
    },
    datumiKojiSuIstekliSuma() {
      return this.$store.getters.sumaIstekli
    },
    datumiSuma() {
      return this.$store.getters.suma
    },
  },
  mounted() {
    this.$store.dispatch('ucitajNotifikacije')
  },
  methods: {
    zatvoriOtvoriMeni() {
      if (this.$route.name !== 'autoskola-notifikacije') {
        this.$router.push('/notifikacije')
      }
      this.$refs.navdropdown.hide()
    },
  },
}
</script>

<style>

</style>
