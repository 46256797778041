<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="curLoc.img"
        height="14px"
        width="22px"
        :alt="curLoc.locale"
      />
      <span class="ml-50 text-body">{{ curLoc.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="setCurrentLocale(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      curLoc: [],
    }
  },
  mounted() {
    this.curLoc = this.locales.find(l => l.locale === 'sr')
    this.$i18n.locale = localStorage.getItem('language')
    this.curLoc = this.locales.find(l => l.locale === localStorage.getItem('language'))
    if (typeof this.curLoc === 'undefined') {
      this.curLoc = this.locales.find(l => l.locale === 'sr')
    }
  },
  methods: {
    setCurrentLocale(locale) {
      this.curLoc = this.locales.find(l => l.locale === locale)
      localStorage.setItem('language', locale)
      this.$i18n.locale = locale
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'sr',
        img: require('@/assets/images/flags/sr.png'),
        name: 'Srpski',
      },
      {
        locale: 'hu',
        img: require('@/assets/images/flags/hu.png'),
        name: 'Hungarian',
      },
    ]
    /* eslint-disable global-require */

    return {
      locales,
    }
  },
}
</script>

<style>

</style>
