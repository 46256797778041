export default [
  {
    title: 'Instruktor',
    icon: 'MenuIcon',
    children: [
      {
        title: 'UnosCasa',
        route: 'instruktori-unos-casa',
        action: 'read',
        resource: 'INSTRUKTOR-UNOS-CASA',
      },
      {
        title: 'CasoviPrakticneObuke',
        route: 'instruktori-casovi-prakticne-obuke',
        action: 'read',
        resource: 'INSTRUKTOR-UNOS-CASA',
      },
      {
        title: 'InstruktorVozila',
        route: 'instruktori-vozila',
        action: 'read',
        resource: 'INSTRUKTOR-VOZILA',
      },
      {
        title: 'Instruktorkandidati',
        route: 'instruktori-kandidati',
        action: 'read',
        resource: 'INSTRUKTOR-KANDIDATI',
      },
    ],
  },
]
