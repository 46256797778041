<template>
  <b-nav-item
    to="/unos-casa"
  >
    <feather-icon
      size="21"
      icon="FilePlusIcon"
    />
  </b-nav-item>
</template>

<script>
import { BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
  },
}
</script>
