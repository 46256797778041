const userData = JSON.parse(localStorage.getItem('userData'))
const route = userData.role === 'admin' ? 'admin-pocetna' : 'instruktori-pocetna'
export default [
  {
    title: 'Pocetna',
    route,
    action: 'read',
    resource: 'DASHBOARD-ANALYTICS',
  },
]
