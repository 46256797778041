export default [
  {
    title: 'Aministrator',
    icon: 'MenuIcon',
    children: [
      {
        title: 'Osnovni podaci',
        route: 'autoskola-osnovni-podaci',
        action: 'read',
        resource: 'AUTOSKOLA-ADMIN-OSNOVNI-PODACI',
      },
      {
        title: 'Zaposleni',
        route: 'autoskola-zasposleni',
        action: 'read',
        resource: 'AUTOSKOLA-ADMIN-ZAPOSLENI',
      },
      {
        title: 'Vozila',
        route: 'autoskola-vozila',
        action: 'read',
        resource: 'AUTOSKOLA-ADMIN-VOZILA',
      },
      {
        title: 'Finansije',
        route: 'finansije',
        action: 'read',
        resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      },
      {
        title: 'Časovi praktične obuke',
        route: 'prakticna-obuka',
        action: 'read',
        resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      },
      {
        title: 'Kandidati',
        route: 'autoskola-kandidati',
        action: 'read',
        resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      },
      {
        title: 'CeneObuke',
        route: 'autoskola-cene-obuke',
        action: 'read',
        resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      },
      {
        title: 'ObrasciUgovora',
        route: 'autoskola-obrasci-ugovora',
        action: 'read',
        resource: 'AUTOSKOLA-ADMIN-KANDIDATI',
      },
    ],
  },
]
